/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyB2UEfaWhL4ndUf13xjqwgKNzN91qkVqT8",
  "appId": "1:392407866700:web:c3bdc856fd9c9e944343e9",
  "authDomain": "schooldog-i-appling-ga.firebaseapp.com",
  "measurementId": "G-2YFGJCP2EY",
  "messagingSenderId": "392407866700",
  "project": "schooldog-i-appling-ga",
  "projectId": "schooldog-i-appling-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-appling-ga.appspot.com"
}
